import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        meta: {title: "宁波艾达人工智能有限公司"}
    },
    {
        path: '/hardware',
        name: 'hardware',
        component: () => import('../views/HardwareView.vue'),
        meta: {title: "产品中心"}
    },
    {
        path: '/software',
        name: 'software',
        component: () => import('../views/SoftwareView.vue'),
        meta: {title: "产品中心"}
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import('../views/ContactUsView.vue'),
        meta: {title: "联系我们"}
    },
    {
        path: '/aboutus',
        name: 'about',
        component: () => import('../views/AboutView.vue'),
        meta: {title: "关于我们"}
    },
    {
        path: '/smart',
        name: 'smart',
        component: () => import('../views/SmartGlassesView.vue'),
        meta: {title: "智能眼镜"}
    },
    {
        path: '/construction',
        name: 'construction',
        component: () => import('../views/ConstructionView.vue'),
        meta: {title: "私有化部署"}
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: () => import('../views/404.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    // 设置页面标题
    if (to.meta.title) {
        document.title = to.meta.title;
    } else {
        document.title = "宁波艾达人工智能有限公司"; // 如果没有指定标题，可以设置一个默认标题
    }
    next();
})

export default router
